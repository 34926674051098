import React, { ComponentType } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	ILogger,
	LoggerSymbol,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import type { OOIComponentLoader } from '../types'
import { name } from '../symbols'
import { MODULE_URL } from '../constants'
import { OOIReporterSymbol, Reporter } from '../reporting'
import { Props } from '../tpaWidgetNativeFactory/tpaWidgetNative'

export default withDependencies(
	[named(SiteFeatureConfigSymbol, name), ViewerModelSym, LoggerSymbol, OOIReporterSymbol, BrowserWindowSymbol],
	(
		{ ooiComponentsData },
		{ siteAssets }: ViewerModel,
		logger: ILogger,
		reporter: Reporter,
		window: NonNullable<BrowserWindow>
	): OOIComponentLoader => {
		let waitForRequireJsToLoad: Promise<unknown> | null = null

		async function loadRequireJS(moduleRepoUrl: string = MODULE_URL) {
			// since react umd bundles do not define named modules, we must load react before loading requirejs.
			// further details here: https://requirejs.org/docs/errors.html#mismatch
			// requirejs will be hopefully removed once ooi comps will be consumed as comp libraries.
			await window.reactAndReactDOMLoaded
			await new Promise((resolve, reject) => {
				const script = document.createElement('script')
				script.src = `${moduleRepoUrl}/requirejs-bolt@2.3.6/requirejs.min.js`
				script.onload = resolve
				script.onerror = reject
				document.head.appendChild(script)
			})
			window.define!('lodash', [], () => _)
			window.define!('reactDOM', [], () => ReactDOM)
			window.define!('react', [], () => React)
			window.define!('imageClientSDK', [], () => window.__imageClientApi__.sdk)

			// @ts-ignore TODO fix requirejs type
			window.requirejs!.onError = (error) => {
				const { requireModules, requireType } = error
				logger.captureError(error, {
					tags: { feature: 'ooi', methodName: 'requirejs.onError' },
					extra: { requireModules, requireType },
				})
			}
		}

		const load = <T>(url: string): Promise<T> =>
			new Promise(async (resolve, reject) => {
				waitForRequireJsToLoad =
					waitForRequireJsToLoad || loadRequireJS(siteAssets.clientTopology.moduleRepoUrl)
				await waitForRequireJsToLoad
				__non_webpack_require__([url], (module: any) => resolve(module), reject)
			})

		return {
			async getComponent(widgetId: string) {
				if (!ooiComponentsData[widgetId]) {
					logger.captureError(new Error('widgetId could not be found in ooiComponentsData'), {
						tags: { feature: 'ooi', methodName: 'getComponent' },
						extra: { widgetId },
					})
					return
				}

				const { componentUrl, sentryDsn } = ooiComponentsData[widgetId]
				const ReactComponent = await load<ComponentType<Props>>(componentUrl)
					.then((module: any) => {
						const component = module?.default?.component
						if (!component) {
							reporter.reportError(new Error('component is not exported'), sentryDsn, {
								tags: { phase: 'ooi component resolution' },
							})
						}
						return component
					})
					.catch(_.noop)
				await window.externalsRegistry.react.loaded // wait for React to load since it is loaded dynamically
				const { ooiReactComponentClientWrapper } = require('../tpaWidgetNativeFactory/tpaWidgetNativeClient')
				return ooiReactComponentClientWrapper(ReactComponent, reporter)
			},
		}
	}
)
